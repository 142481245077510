import * as React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Content from '../../common/Content';
import { IEncryptValues } from '../../interfaces'
import EncryptForm from '../../components/EncryptForm';

interface IProps {
    value: IEncryptValues;
    onChange: (encrypt: IEncryptValues) => void;
}

interface IState{
    value: IEncryptValues;
}

export default function Home(props: IProps){

  return(
    <div className="page-container">
        <Header tagline={Content.Header.tagline}></Header>               
            <EncryptForm />
        <Footer/>       
    </div>      
  )  

}