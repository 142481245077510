import * as React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import Icon from '../Icon'
import { Link } from "react-router-dom";

import './style.scss';

export interface IHeaderProps {
    tagline?: string;
}

library.add(faCogs);

export default function Header(props: IHeaderProps){
    return (
    <div className="gen-header">
        <div className="container header-container">
            <div className="site">
            <Link to="/">
                <span className="icon"><Icon name="cogs"/></span>
                <span className="tagline">{props.tagline || ""}</span>
            </Link>
            </div>
            {/* <ul className="options">
                <li>
                    <a href="tel: 0112 1234 567">
                        <div>
                            <span className="option-text"></span>
                            <span className="option-icon">
                                <span className="icon icon-tel"></span>
                            </span>
                        </div>
                    </a>
                </li>
            </ul> */}
        </div>
    </div>
)};