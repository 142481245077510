import * as React from "react";
import Layout from "../../components/Layout/formHeader";
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Content from '../../common/Content';

export default function CookiePolicy(){

        return (
            <div className="page-container">
            <Header tagline={Content.Header.tagline}/>
            <Layout header="Cookie Policy">
                <div className="grid">
                    <div className="col-m-12 cookie-policy">        
                    <h2>Our use of cookies</h2>
        
                    <p>We use cookies strictly to record your preferences. The website will function perfectly well if you turn off all cookies.  Many sites ask for different
                        length passwords with different combinations of numbers and upper and lower case characters etc.  To make it easier for you we store a cookie on your computer with
                        this information so that when you type in the name of the site again it will automatically fill in the optional items for you.
                    </p>
                    <p>The only cookies we use are to store the following</p>
                    <ul>
                        <li>The text that you input eg 'amazon.co.uk' this is to provide customised autocomplete functionality.</li>
                        <li>The length of the password.</li>
                        <li>The options for the password eg It must contain numbers</li>
                    </ul>                    

                    <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p> 
                    <br />

                    <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p> 
                    <br />

                    <p>Except for essential cookies, all cookies will expire in accordance with their specific provider terms.</p>
                    </div>
                </div>
            </Layout>
            <Footer/> 
            </div>
        );    
}