import { SET_AUTOCOMPLETE, GET_AUTOCOMPLETE, AUTOCOMPLETE_ERROR, RESET_AUTOCOMPLETE } from '../actionTypes';
import { IReducerAction } from '../IReducerAction';

export class AutocompleteState {
    loading = false;    
    error?: any;    
    content: string[];
    
    constructor(){
        this.content = [""];
        this.error = null;
    }
}

    export default function autocompleteReducer(state = new AutocompleteState(), action: IReducerAction) {
        const initialState = new AutocompleteState();
        const { type, payload } = action || {} as IReducerAction;
         switch(type)
        {
            case SET_AUTOCOMPLETE:
                return {...state, content: payload, loading: false};
            case GET_AUTOCOMPLETE:
                return {...state, loading: false}
            case AUTOCOMPLETE_ERROR:
                return {...state, error: payload, loading: false};   
            case RESET_AUTOCOMPLETE:
                return initialState; 
            default:
                return state;        
        }    
    }
