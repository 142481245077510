import * as React from 'react';
import "./style.scss";
import TestResultModal from '../../components/ResultModal/result';
import { useDispatch} from 'react-redux';

export interface IFormHeaderLayoutProps {
    header: string;
    onCopy?: () => void;
    showCopy?: boolean;
    children: React.ReactNode;
}

export default function FormHeaderLayout (props: IFormHeaderLayoutProps) {

    const
        dispatch = useDispatch(),
        [showCopy, setShowCopy] = React.useState(false);
        
    React.useEffect(()=>{
        if(props.showCopy){
            setShowCopy(true);
        }
        else{
            setShowCopy(false);
        }
    },[props.header, props.showCopy, dispatch])


    const onCopy = () => {        
        props.onCopy && props.onCopy();
    }

    return (
        <div className="gen-layout">
        <div className="img-header">
            <div className="container flexbox">
                <div className="grid">
                    <div className="all-hide m-show col-m-12">
                        
                    </div>
                    <div>  
                        <TestResultModal onCopy={onCopy} result={props.header} show={showCopy}/>
                    </div>
                    <div className="col-6 m-hide" id="empty">
                        
                    </div>
                </div>
            </div>
        </div>       
        <div className="container flexbox">
            {props.children}
        </div>
    </div>  
    );
}