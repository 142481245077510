export const 
    SET_VALUES = "SET_VALUES",
    RESET_VALUES = "RESET_VALUES",
    SET_RESULT = "SET_RESULT",
    RESET_RESULT = "RESET_RESULT",
    SET_HEADER = "SET_HEADER";

export const 
    POST_ENCRYPTION = "POST_ENCRYPTION",
    POST_ENCRYPTION_PENDING = "POST_ENCRYPTION_PENDING",
    POST_ENCRYPTION_ERROR = "POST_ENCRYPTION_ERROR",
    RESET_ENCRYPTION = "RESET_ENCRYPTION";

export const
    SET_AUTOCOMPLETE = "SET_AUTOCOMPLETE",
    GET_AUTOCOMPLETE = "GET_AUTOCOMPLETE",
    AUTOCOMPLETE_ERROR = "AUTOCOMPLETE_ERROR",
    RESET_AUTOCOMPLETE = "RESET_AUTOCOMPLETE";

export const RESET_ALL = "RESET_ALL";

