
import { Reducer } from 'redux';
import { createBrowserHistory } from 'history'
import {applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistCombineReducers, PersistConfig} from 'redux-persist';
import {createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import encryptReducer from './reducers/encryptReducer';
import autocompleteReducer from './reducers/autocompleteReducer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { IAppState } from './index';
import { RESET_ALL } from './actionTypes';
import sessionStorage from 'redux-persist/lib/storage';

const
    persistConfig: PersistConfig<IAppState> = {
        key: "root",
        storage: sessionStorage,
        transforms:[
            createWhitelistFilter("router",[]),                        
            createWhitelistFilter("autocompleteReducer", ["content"]),

            createBlacklistFilter("encryptReducer", ["content", "error"])
        ]
    };

const
history = createBrowserHistory(),
appReducer = persistCombineReducers(persistConfig,{        
    router: connectRouter(history) as Reducer,
    encryptReducer,
    autocompleteReducer   
}),
    rootReducer = (state: any, action: any) => {
        //console.log(`rootReducer`)       
        if(action.type===RESET_ALL){
            //Clear out state and session storage when a user cancels      
            state = undefined;
            sessionStorage.removeItem(`persist:root`);
        }


        return appReducer(state, action);
    },
    { __serverState } = (window || {}) as any,
    store = createStore(
        rootReducer,
        __serverState || {},
        composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
    ),
    persistor = persistStore(store, undefined,() =>{});

    export default store;
    export {persistor, history, rootReducer }