import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './features/Home';
import CookiePolicy from './features/CookiePolicy';
import PrivacyPolicy from './features/PrivacyPolicy';
import How from './features/How';
import ScrollToTop from './components/ScrollToTop';

const Router = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <ScrollToTop />
        {/* 
            // Usage for react-router v6 - Can't be used with connected-react-router yet 
            <Routes>
                 <Route path="/" element={<Landing/>}/>
                 <Route path="*" element={<Navigate to="/" />}/>
            </Routes> */}
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/cookie-policy" component={CookiePolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/how-does-it-work" component={How} />
          {/* Not Found */}
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Router;