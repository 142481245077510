import * as React from 'react';
import "./style.scss";
import CopyToClipboard from 'react-copy-to-clipboard';
import Content from '../../common/Content'
import {useDispatch} from 'react-redux'; 
import { RESET_ENCRYPTION } from '../../state/actionTypes';


interface IProps {
    show?: boolean;
    result: string;
    onCopy: () => void
}

export default function TestResultModal(props: IProps) {

    const [show, setShow] = React.useState<boolean | undefined>(props.show);
    const [result, setResult] = React.useState("");
    const dispatch = useDispatch();    
    const runOnce = React.useRef(false);

    React.useEffect(() => {
        setResult(props.result);
        if(props.result !== Content.EncryptForm.header){            
            runOnce.current = true;
        }
    }, [props.result])


    React.useEffect(() => {   
        if(runOnce.current) {
            setShow(props.show);
            runOnce.current = false;
        }
    }, [props.show, show])


    const onCopy = () => {        
        setShow(false);  
        dispatch({type: RESET_ENCRYPTION})      
        props.onCopy && props.onCopy();
    }

    return (

        <div className="copy-container">
            <div className="copy-result">
                <strong>{result}</strong>
            </div>
            {show ?
                <span className="copy-span">
                    <CopyToClipboard text={result}>
                        <button type="button" className="button-copy" onClick={onCopy} title="Copy Password to clipboard">
                            <i className="far fa-copy" />
                            Copy
                        </button>
                    </CopyToClipboard>
                </span>
                : null}
        </div>
    )
}