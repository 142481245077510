import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import Router from './Router'
import './scss/style.scss'



function App() {
  return (
    <Router/>
  );
}

export default App;
