import { POST_ENCRYPTION, POST_ENCRYPTION_ERROR, POST_ENCRYPTION_PENDING, RESET_ENCRYPTION } from '../actionTypes';
import { IReducerAction } from '../IReducerAction';



export class EncryptState {
    loading = false;    
    error?: any;    
    content: string;
    
    constructor(){
        this.content = "";
        this.error = null;
    }
}

    export default function encryptReducer(state = new EncryptState(), action: IReducerAction) {
        const initialState = new EncryptState();
        const { type, payload } = action || {} as IReducerAction;
         switch(type)
        {
            case POST_ENCRYPTION:
                return {...state, content: payload, loading: false};
            case POST_ENCRYPTION_ERROR:
                return {...state, loading: false, error: payload}
            case POST_ENCRYPTION_PENDING:
                return {...state, loading: true}; 
            case RESET_ENCRYPTION:
                return initialState;   
            default:
                return state;        
        }    
    }

 