import * as React from 'react';
import { Link } from 'react-router-dom';
import './style.scss'; 

export default function Footer() {
    return(
        <footer className="gen-footer">
        <div className="container footer-section">
            <input type="button" onClick={e => window.scrollTo(0,0)} className="goto-top" value="Back to top"/>
        </div>

        <div className="footer-section links">
            <div className="container">
                <ul className="list-style2"> 
                    <li><Link to="/">Home</Link></li>                                                  
                    <li><Link to="/cookie-policy">Cookies</Link></li>
                    <li><Link to="/privacy-policy">Your Data</Link></li>
                    <li><Link to="/how-does-it-work" title="How does it work?">Help!</Link></li>
                </ul>
            </div>
        </div>

        <div className="footer-section">
            © {(new Date()).getFullYear()} Acheson-Crow Limited. - All rights reserved
        </div>
    </footer>        
    )
}