import { POST_ENCRYPTION,POST_ENCRYPTION_ERROR,POST_ENCRYPTION_PENDING } from '../../state/actionTypes';
import { IReducerAction } from '../../state/IReducerAction';
import axios, { AxiosResponse, AxiosError }  from 'axios';
import { IEncryptValues } from '../../interfaces';
import { PROD_API_URL } from '../../common'

const API_URL = process.env.NODE_ENV==="development" ? (process.env.REACT_APP_USE_PROD ? PROD_API_URL : process.env.REACT_APP_API) : process.env.REACT_APP_API ;

export function getEncryption(encryptValues: IEncryptValues | null) {
    
    return (dispatch:(arg0: IReducerAction) => any) => {
        dispatch({type: POST_ENCRYPTION_PENDING});
        axios.post(`${API_URL}/api/encryption`, encryptValues)
        .then((res: AxiosResponse) => {
            dispatch({
                type: POST_ENCRYPTION,
                payload: res.data
            })
        }).catch((error: AxiosError) => {
            dispatch({
                type: POST_ENCRYPTION_ERROR,
                payload: error.response?.data
            })
        })
    }
}